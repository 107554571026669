/* Container needed to position the overlay. Adjust the width as needed */
.container {
  position: relative;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
}

/* Make the image to responsive */
.image {
  width: 100%;
  height: 300px;
  border-radius: 10px;
  object-fit: contain
}

/* The overlay effect (full height and width) - lays on top of the container and over the image */
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 300px;
  width: 100%;
  opacity: 0;
  transition: .3s ease;
  background-color: black;
  border-radius: 10px;
}

/* When you mouse over the container, fade in the overlay icon*/
.container:hover .overlay {
  opacity: 0.5;
}

/* The icon inside the overlay is positioned in the middle vertically and horizontally */
.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  cursor: pointer;
}

.inner-icon {
  background-color: white;
  color: black;
  border-radius: 50%;
}