.overview-header-first-icon {
    width: 15px;
    background-color: #CABDFF;
    height: 30px;
    border-radius: 5px
}

.overview-header-second-icon {
    width: 15px;
    background-color: #35E4CA;
    height: 30px;
    border-radius: 5px
}

.ad-set-first-icon {
    width: 15px;
    background-color: #f0a7a7;
    height: 30px;
    border-radius: 5px
}

.table-header {
    color: grey;
    font-weight: bold;
}

.table-language-green {
    color: #90C67D;
    background-color: #EAFAE4;
    padding: 5px;
    border-radius: 5px
}

.table-language-red {
    color: #FF6A55;
    background-color: #FFE7E4;
    padding: 5px;
    border-radius: 5px
}

.post-avatar-flex {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start
}

.section-products-header-flex {
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.section-overview-header-flex {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center
}

.actions-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    padding: 20px
}

.news-name-style {
    font-weight: 600;
    white-space: nowrap
}

.news-name-style-title {
    font-size: 17px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    overflow: hidden;
    cursor: pointer;
}

.news-name-style-title:hover {
    text-decoration: underline;
}