.PhotoView-Slider__BannerWrap {
    align-items: center;
    background-color: rgba(0,0,0,.5);
    color: #fff;
    display: flex;
    height: 44px;
    justify-content: flex-end;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity .2s ease-out;
    width: 100%;
    z-index: 20;
}

.PhotoView-Slider__Counter {
    display: none;
}

.section-news-header-flex {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}


.news-actions-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
}