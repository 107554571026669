body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* App.js body maxWidth */

.body-center {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0px
}

.body-width {
  width: 100%;
  max-width: 1500px;
  flex-grow: 1;
  padding: 0px
}

/* Drawer Style*/

.MuiDrawer-paper {
  background-color: #f2f1f1 !important;
  color: white !important;
}

.css-sn31f5-MuiPaper-root-MuiAppBar-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: static;
  background-color: #1976d2;
  color: #fff;
  background-color: #f2f1f1;
}

/* Tab Navigation Style */

.tab-navigation-style {
  font-family: 'Noto Sans Malayalam', sans-serif;
  font-size: 16px;
  font-weight: 700;
}

/* Features Component Style*/

.card-content {
  padding: 20px;
}

.card-content-for-world-and-news-session {
  padding: 0px 20px 0px 20px;
}

.hover-blue-title-text-header,
.hover-blue-title-text-header * {
  font-family: 'Noto Sans Malayalam', sans-serif;
  font-size: 17px;
  /* font-weight: bold; */
  line-height: 25px;
  /* margin: 10px 0px 20px 0px; */
  cursor: pointer;
  transition: color 0.3s;
  color: black !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* Limit to 3 lines */
  overflow: hidden;
}

.hover-blue-title-text-header:hover,
.hover-blue-title-text-header:hover * {
  color: #176B87 !important;
}

.description-text-style,
.description-text-style * {
  font-family: 'Noto Sans Malayalam', sans-serif;
  font-size: 15px;
  /* font-weight: 300; */
  /* margin: 10px 0px 20px 0px; */
  line-height: 21px;
  color: black !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* Limit to 3 lines */
  overflow: hidden;
}

.date-time-text-style {
  font-family: 'Noto Sans Malayalam', sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin: 0px;
  color: black;
}

/* World Component view,date Flex */

.worldFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  padding: 0px 20px 0px 20px
}

.worldChildFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px
}

/* Editors Pick */

.editors-pick-header {
  font-family: 'Noto Sans Malayalam', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 21px;
  margin: 20px;
}

#editorjs h1,
#editorjs h2,
#editorjs h3,
#editorjs h4,
#editorjs h5,
#editorjs h6 {
  font-weight: normal !important;
}

/* Featured Article */

.featured-article-header {
  font-family: 'Noto Sans Malayalam', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 21px;
  margin: 20px;
}

.category-card-green {
  color: #90C67D;
  background-color: #EAFAE4;
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  top: 125px;
  left: 20px;
}

.category-card-black {
  color: black;
  font-weight: bold;
  background-color: #ffffff;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.news-details-title-text-header,
.news-details-title-text-header * {
  font-family: 'Noto Sans Malayalam', sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  /* margin: 10px 0px 20px 0px; */
  /* cursor: pointer; */
  transition: color 0.3s;
  color: black !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* Limit to 3 lines */
  overflow: hidden;
}

/* .news-details-description-text-style,
.news-details-description-text-style * {
  font-family: 'Noto Sans Malayalam', sans-serif;
  font-size: 15px;
  font-weight: 300; */
/* margin: 10px 0px 20px 0px; */
/* line-height: 21px;
  color: black !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
} */

.news-details-category-card-green {
  /* color: #90C67D; */
  color: green;
  background-color: #EAFAE4;
  padding: 5px;
  border-radius: 5px;
  /* position: absolute;
  top: 20px;
  left: 20px; */
}

/* More news button style */

.more-news-container-style {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 20px;
  padding: 0px 20px 20px 0;
}

.more-from-latest-news-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  font-weight: bold;
  cursor: pointer;
}

.more-from-latest-news-style:hover {
  color: #176B87 !important;
}

.image-responsive-slider {
  width: 100%;
  height: 325px;
}

/* Swiper Naviagtion Arrow */

/* Change the color of the navigation arrows */
.swiper-button-next,
.swiper-button-prev {
  color: #FFF !important;
  position: absolute;
  top: var(--swiper-navigation-top-offset, 40%) !important;
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description-text-style-for-mobile-and-desktop,
.description-text-style-for-mobile-and-desktop * {
  font-family: 'Noto Sans Malayalam', sans-serif;
  font-size: 15px;
  /* font-weight: 300; */
  /* margin: 10px 0px 20px 0px; */
  line-height: 21px;
  color: black !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* Limit to 3 lines */
  overflow: hidden;
}

/* Adjustments for Tablet */
@media (max-width: 1024px) {

  .swiper-button-next,
  .swiper-button-prev {
    top: var(--swiper-navigation-top-offset, 35%) !important;
  }
}

/* Adjustments for Mobile */
@media (max-width: 768px) {

  .swiper-button-next,
  .swiper-button-prev {
    top: var(--swiper-navigation-top-offset, 25%) !important;
  }

  .image-responsive-slider {
    width: 100%;
    height: 150px;
  }

  .description-text-style-for-mobile-and-desktop,
  .description-text-style-for-mobile-and-desktop * {
    display: none;
  }
}

/* Footer Style */
.footer-link-style {
  color: white;
  text-decoration: none;
}

.footer-link-style:hover {
  color: #176B87 !important;
}

.footer-text {
  line-height: 26px;
  font-size: 16px;
  overflow-wrap: break-word;
  letter-spacing: 10;
}

/* New React quill Editor style */

.ql-editor {
  box-sizing: border-box;
  line-height: 1.42;
  height: 400px !important;
  outline: none;
  overflow-y: auto;
  padding: 12px 15px;
  tab-size: 4;
  -moz-tab-size: 4;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ql-snow .ql-tooltip {
  position: initial !important;
  transform: translateY(10px);
}

.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 18px !important;
  height: 100%;
  margin: 0px;
  position: relative;
}

.ql-snow .ql-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px #ddd;
  color: #444;
  padding: 5px 12px;
  white-space: wrap !important;
}

/* editor custom font in px */
.ql-snow {
  .ql-picker {
    &.ql-size {

      .ql-picker-label,
      .ql-picker-item {
        &::before {
          content: attr(data-value) !important;
        }
      }
    }
  }
}

.ql-toolbar.ql-snow .ql-formats {
  margin: 5px 0px 5px 0px !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.ql-snow .ql-picker.ql-size {
  width: 98px;
  height: 25px !important;
  font-size: 20px !important;
}

.ql-snow.ql-toolbar button svg,
.ql-snow .ql-toolbar button svg {
  float: left;
  height: 25px !important;
  width: 25px !important;
}

.ql-snow .ql-color-picker .ql-picker-label svg,
.ql-snow .ql-icon-picker .ql-picker-label svg {
  right: 4px;
  height: 25px !important;
  width: 25px !important;
}

/* News Details Custom Style */

.custom-html-content-news-Details img {
  width: 100%;
  height: 100%;
}

.custom-html-content-news-Details iframe {
  width: 100%;
  height: 500px;
}

.news-details-sub-session-header-style {
  background-color: #3a98c9;
  font-family: 'Noto Sans Malayalam', sans-serif;
  font-size: 20px;
  color: white;
  font-weight: 500;
  margin: 20px;
  border-radius: 5px;
  padding: 10px;
  width: fit-content
}

.news-details-sub-latest-session-header-style {
  background-color: #176B87;
  font-family: 'Noto Sans Malayalam', sans-serif;
  font-size: 18px;
  color: white;
  font-weight: 500;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.news-details-title {
  font-size: 30px;
  line-height: 34px;
  font-weight: 400;
  margin: 10px 0;
}

.uploadBtn {
  position: relative;
}

.uploadBtn label {
  position: absolute;
  left: 0;
  top: 0;
  border: 1px solid #e4e4e4;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: column-reverse;
  background-color: #fff;
  border-radius: 5px;
  text-align: center;
}

.uploadBtn label::after {
  content: '';
  background-image: url(../public/upload-icon.svg);
  width: 75px;
  height: 75px;
  margin: 0 auto;
  background-color: #fff;

}

.uploadBtn input {
  opacity: 0;
}

/* Latest session date style */
.latest-session-date-session {
  font-family: 'Noto Sans Malayalam', sans-serif;
  color: #176B87;
  font-size: 12px;
  font-weight: 400;
}