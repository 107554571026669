#editorjs {
    /* Your default styles here */
    border: 2px solid rgba(201, 201, 204, .48);
    transition: border-color 0.3s ease-in-out;
    /* Add a smooth transition effect */
    /* margin-left: 20px;
    margin-right: 20px; */
  }
  
  #editorjs:hover {
    border-color: #388AE5;
    /* Change the border color on hover */
  }

  /* .ce-toolbar__actions {
    position: absolute;
    right: 100%;
    opacity: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-right: 10px;
} */

/* .ce-toolbar__actions--opened {
    opacity: 1;
} */

.section-new-category-add-news-category-flex {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}

.section-new-category-add-news-content-flex {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
}