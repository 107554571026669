/* container.css */

/* .AdvertiseItem {
    border: 2px solid #ddd;
    padding: 50px;
    height: 120%;
    width: 50%;
    text-align: center;
    margin-bottom: 10px;
    
  }
  
  .AdvertiseItem img {
    width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  .AdvertiseItem p {
    margin: 5px 0;
  } */

/* Optionally, you can add more styles as needed */
/* Mobile View */
/* @media (max-width: 600px) {
    .AdvertiseItem {
      width: 90%;
      padding: 20px;
    }
  } */

/* Desktop View */
/* @media (min-width: 601px) {
    .AdvertiseItem {
      width: 50%;
      height: 120%;
    }
  }.container {
    padding-top: 20px;
  } */

.matrimony-btn-flex {
  display: flex;
  justify-content: center;
  /* padding-bottom: 20px;
  padding-top: 20px */
}

.matrimony-image {
  width: 100%;
  height: 200px;
}

.matrimony-id-text,
.matrimony-id-text * {
  font-size: 17px;
  /* font-weight: bold; */
  line-height: 25px;
  font-family: Noto Sans Malayalam, sans-serif;
  margin: 10px;
  cursor: pointer;
  color: black !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;/* Limit to 3 lines */
  overflow: hidden;
}

.matrimony-id-text:hover,
.matrimony-id-text:hover * {
  color: #176B87 !important;
}

.matrimony-desc-text,
.matrimony-desc-text * {
  font-size: 15px;
  font-family: Noto Sans Malayalam, sans-serif;
  margin: 5px;
  color: black !important;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;/* Limit to 3 lines */
  overflow: hidden;
}

.matrimony-box-img {
  height: 100%;
  width: 100%;
}

.obitary-matrimony-iframe-style {
  width: 100%;
  height: 200px;
}